let url;

//url = 'http://localhost:3001'
url = 'https://gridlocke.herokuapp.com'

const state = {
    hand: new Map(),
    serverUrl: url
}

export default state;
